import { Injectable } from '@angular/core';
import { IPatient, IPatientExamination, IPatientWeight } from '../../shared/models/patient.model';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppConfigService } from '@app/modules/core/config/config.service';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  public patientUpdated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public patientExaminationsChanged: Subject<number> = new Subject();

  private apiUrl: string;
  private appUrl: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.apiUrl = this.appConfig.apiUrl;
    this.appUrl = this.appConfig.baseUrl;
  }

  public addPatient(patient: Partial<IPatient>): Observable<IPatient> {
    return this.http.post<IPatient>(`${this.apiUrl}/patients`, patient);
  }

  public savePatient(patient: Partial<IPatient>): Observable<IPatient> {
    return this.http.patch<IPatient>(`${this.apiUrl}/patients/${patient.id}`, patient);
  }

  public getPatient(id: number): Observable<IPatient> {
    return this.http.get<IPatient>(`${this.apiUrl}/patients/${id}`);
  }

  public getPatientWeights(id: number): Observable<IPatientWeight[]> {
    return this.http.get<IPatientWeight[]>(`${this.apiUrl}/patients/${id}/weights`);
  }

  public getPatientExaminations(
    id: number,
    items = 10,
    page = 1,
    headers: HttpHeaders = null
  ): Observable<HttpResponse<IPatientExamination[]>> {
    return this.http.get<IPatientExamination[]>(`${this.apiUrl}/patients/${id}/examinations`, {
      params: {
        sort: ['created_at desc'],
        items: items,
        page: page,
      },
      headers: headers,
      observe: 'response',
    });
  }

  public addPatientExaminations(patientId: number, examinationData): Observable<IPatientExamination> {
    return this.http.post<IPatientExamination>(`${this.apiUrl}/patients/${patientId}/examinations`, examinationData);
  }

  public editPatientExamination(
    patientId: number,
    examinationId: number,
    examinationData
  ): Observable<IPatientExamination> {
    return this.http.patch<IPatientExamination>(
      `${this.apiUrl}/patients/${patientId}/examinations/${examinationId}`,
      examinationData
    );
  }

  public deleteExamination(patientId: number, id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUrl}/patients/${patientId}/examinations/${id}`);
  }

  public addPatientPhotos(id: number, photos): Observable<IPatient> {
    return this.http.post<IPatient>(`${this.apiUrl}/patients/${id}/photos`, photos);
  }

  public deletePatient(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUrl}/patients/${id}`);
  }

  public getPayCommitmentPdf(data): Observable<any> {
    console.log('data in servcie', data);

    return this.http.post(`${this.appUrl}/patients/${data.patient.id}/payment_obligation.pdf`, {
      amount: data.amount,
      date: data.date,
    });
  }
}
