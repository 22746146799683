import { Injectable } from '@angular/core';
import { State, Action, StateContext, ofAction, Actions } from '@ngxs/store';
import { Clinics, Users, Patients, Owners, App } from './app.actions';
import { IOwner, IOwnerForm, IPatient, IPatientForm } from '@app/modules/shared/models';
import { ClinicStatus, DoctorStatus } from '@app/modules/shared/enums';
import { catchError, map, of, takeUntil, tap } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { NavigationService } from '@app/modules/shared/services/navigation.service';
import { HomeService } from '@app/modules/home/services/home.service';
import { BIRTHDAYS_PER_PAGE, formattedToday } from '@app/modules/home/helpers/birthdays.helper';
import { FormGroup } from '@angular/forms';
import { OwnerService } from '@app/modules/owner/services/owner.service';
import { PatientService } from '@app/modules/owner/services/patient.service';
import { initialDataGQL } from '@app/graphql';
import { activeClinicsGQL } from '@app/graphql/queries/active-clinics.query';
import { mapOwnerFormValuesForApi } from '@app/modules/owner/helpers/owners.helper';
import { AppStateModel } from './app-state.model';
import { FormService } from '@app/modules/core/services/form.service';

const defaults = {
  activeClinics: null,
  currentClinic: null,
  allClinics: null,
  activeUsers: null,
  currentUser: null,
  currentCompany: null,
  birthdays: null,
  currentOwner: null,
  currentPatient: null,
  currentPatientWeights: null,
  currentPatientExaminations: null,
};

@State<AppStateModel>({
  name: 'app',
  defaults,
})
@Injectable()
export class AppState {
  constructor(
    private actions$: Actions,
    private initialDataGQL: initialDataGQL,
    private activeClinicsGQL: activeClinicsGQL,
    private navigationService: NavigationService,
    private homeService: HomeService,
    private ownerService: OwnerService,
    private patientService: PatientService,
    private formService: FormService
  ) {}

  @Action(App.LoadCurrentUserInitialData)
  loadCurrentUserInitialData(context: StateContext<AppStateModel>) {
    return this.initialDataGQL
      .fetch({
        activeClinicStatus: ClinicStatus.Active,
        allClinicsStatuses: [ClinicStatus.Active, ClinicStatus.Disabled, ClinicStatus.Deleted],
        activeUserStatus: DoctorStatus.Active,
      })
      .pipe(
        map(({ data }) => data),
        tap((data) => {
          const state = context.getState();
          context.setState({
            ...state,
            currentUser: data.me.doctor,
            currentClinic: data.me.clinic,
            currentCompany: data.me.company,
            activeUsers: data.activeUsers,
            activeClinics: data.activeClinics,
            allClinics: data.allClinics,
          });
        }),
        takeUntil(this.actions$.pipe(ofAction(Users.Logout)))
      );
  }

  @Action(Clinics.LoadActive)
  loadActiveClinics(context: StateContext<AppStateModel>) {
    const existingActiveClinics = context.getState().activeClinics;
    if (existingActiveClinics) {
      return context.getState().activeClinics;
    }

    return this.activeClinicsGQL
      .fetch({
        $active: ClinicStatus.Active,
      })
      .pipe(
        map(({ data }) => data),
        tap((data) => {
          const state = context.getState();
          context.setState({
            ...state,
            activeClinics: data.activeClinics,
          });
        }),
        takeUntil(this.actions$.pipe(ofAction(Users.Logout)))
      );
  }

  @Action(Users.Logout)
  logout(context: StateContext<AppStateModel>) {
    return this.navigationService.logout().pipe(
      tap(() => {
        context.setState({ ...defaults });
      })
    );
  }

  @Action(Patients.LoadBirthdays)
  loadBirthdays(context: StateContext<AppStateModel>, payload: { page: number; headers: HttpHeaders }) {
    return this.homeService.getBirthdays(formattedToday(), BIRTHDAYS_PER_PAGE, payload.page, payload.headers).pipe(
      tap((birthdays) => {
        const state = context.getState();
        context.setState({
          ...state,
          birthdays,
        });
      }),
      takeUntil(this.actions$.pipe(ofAction(Users.Logout)))
    );
  }

  @Action(Owners.Add)
  addOwner(context: StateContext<AppStateModel>, payload: { ownerForm: FormGroup<IOwnerForm> }) {
    const ownerData = mapOwnerFormValuesForApi(payload.ownerForm);

    return this.ownerService.addOwner(ownerData).pipe(
      tap((owner) => {
        return context.dispatch(new Owners.SetCurrent(owner));
      }),
      catchError((error) => {
        this.formService.defaultErrorHandler(error, payload.ownerForm);
        return of(error);
      }),
      takeUntil(this.actions$.pipe(ofAction(Users.Logout)))
    );
  }

  @Action(Owners.Edit)
  editOwner(context: StateContext<AppStateModel>, payload: { ownerForm: FormGroup<IOwnerForm> }) {
    const ownerData = mapOwnerFormValuesForApi(payload.ownerForm);

    return this.ownerService.saveOwner(ownerData).pipe(
      tap((owner) => {
        return context.dispatch(new Owners.SetCurrent(owner));
      }),
      takeUntil(this.actions$.pipe(ofAction(Users.Logout)))
    );
  }

  @Action(Owners.Load)
  loadOwner(context: StateContext<AppStateModel>, payload: { id: number }) {
    return this.ownerService.getOwner(+payload.id).pipe(
      tap((owner) => {
        return context.dispatch(new Owners.SetCurrent(owner));
      }),
      takeUntil(this.actions$.pipe(ofAction(Users.Logout)))
    );
  }

  @Action(Owners.SetCurrent)
  setCurrentOwner(context: StateContext<AppStateModel>, payload: { owner: IOwner }) {
    const state = context.getState();
    context.setState({
      ...state,
      currentOwner: payload.owner,
    });
  }

  @Action(Owners.LoadCurrent)
  loadCurrentOwner(context: StateContext<AppStateModel>, payload: { id: number }) {
    const state = context.getState();
    const isNewOwner = +payload.id !== +state.currentOwner?.id;

    if (isNewOwner) {
      return context.dispatch(new Owners.Load(+payload.id));
    }
  }

  @Action(Owners.AddPatient)
  addOwnersPatient(context: StateContext<AppStateModel>, payload: { patient: IPatient }) {
    const state = context.getState();
    const newOwnerPatients = [...state.currentOwner?.patients, payload.patient];

    const updatedOwner: IOwner = {
      ...state.currentOwner,
      patients: newOwnerPatients,
    };

    context.setState({
      ...state,
      currentOwner: state.currentOwner.id === payload.patient.owner_id ? updatedOwner : state.currentOwner,
    });
  }

  @Action(Patients.Add)
  addPatient(context: StateContext<AppStateModel>, payload: { patientForm: FormGroup<IPatientForm> }) {
    const form = payload.patientForm;

    return this.patientService.addPatient(form.value).pipe(
      tap((patient) => {
        this.patientService.patientUpdated.next(true);
        return [context.dispatch(new Owners.AddPatient(patient)), context.dispatch(new Patients.SetCurrent(patient))];
      }),
      catchError((error) => {
        this.patientService.patientUpdated.next(false);
        this.formService.defaultErrorHandler(error, payload.patientForm);
        return of(error);
      }),
      takeUntil(this.actions$.pipe(ofAction(Users.Logout)))
    );
  }

  @Action(Patients.Edit)
  editPatient(context: StateContext<AppStateModel>, payload: { patientForm: FormGroup<IPatientForm> }) {
    const state = context.getState();
    const form = payload.patientForm;

    return this.patientService.savePatient(form.value).pipe(
      tap((patient: IPatient) => {
        this.patientService.patientUpdated.next(true);

        const patientExists = state.currentOwner?.patients.some((p) => +p.id === +patient.id);
        const updateOwnerPatients = state.currentOwner.patients.map((oldPatient) => {
          return oldPatient.id === patient.id ? patient : oldPatient;
        });

        const updatedOwner: IOwner = {
          ...state.currentOwner,
          patients: updateOwnerPatients,
        };

        return [
          context.dispatch(new Owners.SetCurrent(patientExists ? updatedOwner : state.currentOwner)),
          context.dispatch(new Patients.SetCurrent(patient)),
        ];
      }),
      catchError((error) => {
        this.patientService.patientUpdated.next(false);
        this.formService.defaultErrorHandler(error, payload.patientForm);
        return of(error);
      }),
      takeUntil(this.actions$.pipe(ofAction(Users.Logout)))
    );
  }

  @Action(Patients.Remove)
  removePatient(context: StateContext<AppStateModel>, payload: { id: number }) {
    return this.patientService.deletePatient(payload.id).pipe(
      tap(() => {
        const state = context.getState();
        const patientExists = state.currentOwner?.patients.some((p) => +p.id === +payload.id);
        const updateOwnerPatients = state.currentOwner?.patients.filter((patient) => +patient.id !== +payload.id);

        const updatedOwner: IOwner = {
          ...state.currentOwner,
          patients: updateOwnerPatients,
        };

        return [
          context.dispatch(new Owners.SetCurrent(patientExists ? updatedOwner : state.currentOwner)),
          context.dispatch(new Patients.SetCurrent(null)),
        ];
      }),
      takeUntil(this.actions$.pipe(ofAction(Users.Logout)))
    );
  }

  @Action(Patients.Load)
  loadPatient(context: StateContext<AppStateModel>, payload: { id: number }) {
    return this.patientService.getPatient(+payload.id).pipe(
      tap((owner) => {
        return context.dispatch(new Patients.SetCurrent(owner));
      }),
      takeUntil(this.actions$.pipe(ofAction(Users.Logout)))
    );
  }

  @Action(Patients.SetCurrent)
  setCurrentPatient(context: StateContext<AppStateModel>, payload: { patient: IPatient }) {
    const state = context.getState();
    context.setState({
      ...state,
      currentPatient: payload.patient,
    });
  }

  @Action(Patients.LoadCurrent)
  loadCurrentPatient(context: StateContext<AppStateModel>, payload: { id: number }) {
    const state = context.getState();
    const isNewPatient = +payload.id !== +state.currentPatient?.id;

    if (isNewPatient) {
      return context.dispatch(new Patients.Load(+payload.id));
    }
  }

  @Action(Patients.LoadWeight)
  loadCurrentPatientWeights(context: StateContext<AppStateModel>, payload: { id: number }) {
    return this.patientService.getPatientWeights(payload.id).pipe(
      tap((weights) => {
        const state = context.getState();
        context.setState({
          ...state,
          currentPatientWeights: weights,
        });
      }),
      takeUntil(this.actions$.pipe(ofAction(Users.Logout)))
    );
  }

  @Action(Patients.LoadExaminations)
  loadCurrentPatientExaminations(context: StateContext<AppStateModel>, payload: { id: number }) {
    return this.patientService.getPatientExaminations(payload.id).pipe(
      tap((examinations) => {
        const state = context.getState();
        context.setState({
          ...state,
          currentPatientExaminations: examinations,
        });
      }),
      takeUntil(this.actions$.pipe(ofAction(Users.Logout)))
    );
  }
}
