import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(protected translate: TranslateService, private toastr: ToastrService) {}

  public defaultErrorHandler(error: any, form: UntypedFormGroup): void {
    if (error.status === 422 && form) {
      this.setServerValidationErrors(form, error);
    } else {
      this.showDefaultErrorToasts(error);
    }
  }

  public setServerValidationErrors(form: UntypedFormGroup, httpError: HttpErrorResponse): Observable<never> {
    if (httpError.status !== 422) {
      return;
    }

    httpError.error.error.messages.map((message) => {
      const formFields: AbstractControl[] = [];

      Object.values(form.controls).forEach((control) => {
        if ((control as UntypedFormGroup).controls) {
          formFields.push(control.get(message.path[0]));
        } else {
          formFields.push(form.get(message.path[0]));
        }
      });

      if (formFields.length) {
        formFields.forEach((formField: AbstractControl) => {
          if (formField) {
            formField.markAsDirty();
            formField.markAsTouched();
            formField.setErrors({
              serverError:
                this.translate.instant('CORE.ERRORS.FIELDS.' + message.path[0].toUpperCase()) + ' ' + message.text,
            });
          }
        });
      } else {
        console.log('Form field missing', message.path[0]);
      }
    });

    return throwError(() => {
      return {
        handled: true,
        code: httpError.error.error_code,
        description: this.translate.instant('CORE.ERRORS.FORM-ERROR'),
      };
    });
  }

  public showDefaultErrorToasts(error: any): void {
    const errorMessages = error.error.error.messages;

    if (errorMessages.length) {
      // already handled error
      if (!error.handled) {
        errorMessages.forEach((message) => {
          if (message.path?.length) {
            this.toastr.error(
              this.translate.instant('CORE.ERRORS.FIELDS.' + message.path[0].toUpperCase()) + ' ' + message.text
            );
          } else {
            this.toastr.error(message.text);
          }
        });
      }
    } else {
      this.toastr.error(this.translate.instant('CORE.ERRORS.NETWORK-ERROR'));
    }
  }

  public prepareFormForApi(form: UntypedFormGroup): void {
    Object.keys(form.controls).forEach((key) => {
      form.get(key).markAsDirty();
      form.get(key).markAsTouched();

      if ((form.get(key) as UntypedFormGroup).controls) {
        const nestedForm = form.get(key) as UntypedFormGroup;

        Object.keys(nestedForm.controls).forEach((subKey) => {
          nestedForm.get(subKey).markAsDirty();
          nestedForm.get(subKey).markAsTouched();

          if (nestedForm.get(subKey).value === '') {
            nestedForm.get(subKey).setValue(null);
          }
        });
      }

      if (form.get(key).value === '') {
        form.get(key).setValue(null);
      }
    });
  }

  public addModalBackdrop(): void {
    document.getElementsByClassName('second')[0].parentElement.style.backgroundColor = 'rgba(0, 0, 0, 0.4)';
  }
}
