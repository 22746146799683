import { Injectable, Inject, NgZone } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, map, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { RollbarService } from '../../../rollbar';
import Rollbar from 'rollbar';

@Injectable()
export class HandleErrorsInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private zone: NgZone,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<unknown>) => event),
      catchError((err) => {
        let title = this.translate.instant('CORE.ERRORS.HEADER');
        let message = this.translate.instant('CORE.ERRORS.' + err.error.message?.toUpperCase().replace(' ', '-'));

        if (err.status === 404) {
          this.router.navigate(['/404']);
        }

        if (err.status === 401) {
          title = this.translate.instant('CORE.401.MESSAGE.TITLE');
          message = this.translate.instant('CORE.401.MESSAGE.CONTENT');

          this.zone.run(() => {
            this.router.navigate(['login']);
          });
        }

        if (err.status !== 422) {
          this.rollbar.error(err);
        }

        this.toastr.error(message, title);

        throw err;
      })
    );
  }
}
