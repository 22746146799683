import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@app/modules/core/config/config.service';
import { IPatient } from '@app/modules/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private apiUrl: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  public getBirthdays(
    date: string,
    items = 10,
    page = 1,
    headers: HttpHeaders = null
  ): Observable<HttpResponse<IPatient[]>> {
    const params = {
      'query[birthday_day_eq]': date,
      'query[has_died_eq]': 0,
    };

    return this.http.get<IPatient[]>(`${this.apiUrl}/patients`, {
      params: {
        ...params,
        items: items,
        page: page,
      },
      headers: headers,
      observe: 'response',
    });
  }
}
