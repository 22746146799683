import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, InjectionToken, LOCALE_ID, NgModule, ErrorHandler, Injector } from '@angular/core';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import { TranslateLoader, TranslateModule, TranslateParser, TranslateService } from '@ngx-translate/core';
import { SupportedLanguagesCodes } from './modules/core/enums/supported-language-codes.enum';
import { LanguageCustomLoader } from '../language-custom-loader';
import localePl from '@angular/common/locales/pl';
import localePlExtra from '@angular/common/locales/extra/pl';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { LoadingService } from './modules/core/services/loading.service';
import { EncodeUrlParamsSafelyInterceptor } from './modules/core/interceptors/encode-params-safely.interceptor';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { CoreModule } from './modules/core/core.module';
import { ToastrModule } from 'ngx-toastr';
import { TranslateICUParser } from 'ngx-translate-parser-plural-select';
import { CookieInterceptor } from './modules/core/interceptors/cookie.interceptor';
import { LoadingInterceptor } from './modules/core/interceptors/loading.interceptor';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthInterceptor } from './modules/core/interceptors/auth.interceptor';
import { HandleErrorsInterceptor } from './modules/core/interceptors/handle-errors.interceptor';
import { registerLicense } from '@syncfusion/ej2-base';
import { NgxsModule } from '@ngxs/store';
import { AppState } from './store/app/app.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { GraphQLModule } from './graphql.module';
import { IAppConfig } from './modules/core/models';
import { AppConfigService } from './modules/core/config/config.service';
import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar';

export const APP_CONFIG: InjectionToken<IAppConfig> = new InjectionToken<IAppConfig>('Application Configuration');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: customLoaderFactory,
      },
      parser: {
        provide: TranslateParser,
        useClass: TranslateICUParser,
      },
    }),
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    GraphQLModule,
    NgxsModule.forRoot([AppState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  providers: [
    {
      provide: SwRegistrationOptions,
      useFactory: (config: AppConfigService) => ({ enabled: config.production }),
      deps: [AppConfigService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfigService) => () => {
        return new Promise<void>((resolve) => {
          registerLicense(config.syncfusion.license);
          resolve();
        });
      },
      deps: [AppConfigService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    LoadingService,
    {
      provide: LOCALE_ID,
      useValue: 'pl-PL',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeUrlParamsSafelyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CookieInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleErrorsInterceptor,
      multi: true,
    },
    BsModalService,
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory, deps: [Injector] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateService) {
    registerLocaleData(localePl, 'pl', localePlExtra);
    this.setLanguage(SupportedLanguagesCodes.Polish);
  }

  private setLanguage(lang: string): void {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }
}

export function customLoaderFactory(): LanguageCustomLoader {
  return new LanguageCustomLoader();
}
