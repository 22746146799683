import { Injectable } from '@angular/core';
import { ActiveClinicsResponse } from '@app/modules/shared/models';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class activeClinicsGQL extends Query<ActiveClinicsResponse> {
  document = gql`
    query ($active: [ClinicStatus!]) {
      activeClinics: clinicsList(statusIn: $active) {
        collection {
          id
          name
        }
        metadata {
          totalCount
        }
      }
    }
  `;
}
