import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IOwner } from '../../shared/models/owner.model';
import { AppConfigService } from '@app/modules/core/config/config.service';

@Injectable({
  providedIn: 'root',
})
export class OwnerService {
  private apiUrl: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  public addOwner(owner: Partial<IOwner>): Observable<IOwner> {
    return this.http.post<IOwner>(`${this.apiUrl}/owners`, owner);
  }

  public saveOwner(owner: Partial<IOwner>): Observable<IOwner> {
    return this.http.patch<IOwner>(`${this.apiUrl}/owners/${owner.id}`, owner);
  }

  public getOwner(id: number): Observable<IOwner> {
    return this.http.get<IOwner>(`${this.apiUrl}/owners/${id}`);
  }

  public deleteOwner(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUrl}/owners/${id}`);
  }

  public payOwnerDebt(owner_id: number, clinic_id: number, amount: number): Observable<IOwner> {
    return this.http.patch<IOwner>(`${this.apiUrl}/owners/${owner_id}/pay_debt`, { amount, clinic_id });
  }
}
