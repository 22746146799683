import { Selector } from '@ngxs/store';
import { AppState } from './app.state';
import {
  IClinicResponseData,
  IClinicShortResponse,
  ICompanyShortResponse,
  IDoctorResponseData,
  IDoctorShortResponse,
  IOwner,
  IPatient,
} from '@app/modules/shared/models';
import { HttpResponse } from '@angular/common/http';
import { AppStateModel } from './app-state.model';

export class AppStateSelectors {
  @Selector([AppState])
  static allClinics(state: AppStateModel): IClinicResponseData {
    return state.allClinics;
  }

  @Selector([AppState])
  static activeClinics(state: AppStateModel): IClinicResponseData {
    return state.activeClinics;
  }

  @Selector([AppStateSelectors.activeClinics])
  static activeClinicsNodes(state: IClinicResponseData): IClinicShortResponse[] {
    return state.collection;
  }

  @Selector([AppState])
  static currentClinic(state: AppStateModel): IClinicShortResponse {
    return state.currentClinic;
  }

  @Selector([AppState])
  static activeUsers(state: AppStateModel): IDoctorResponseData {
    return state.activeUsers;
  }

  @Selector([AppState])
  static currentUser(state: AppStateModel): IDoctorShortResponse {
    return state.currentUser;
  }

  @Selector([AppState])
  static currentCompany(state: AppStateModel): ICompanyShortResponse {
    return state.currentCompany;
  }

  @Selector([AppState])
  static birthdays(state: AppStateModel): HttpResponse<IPatient[]> {
    return state.birthdays;
  }

  @Selector([AppState])
  static currentOwner(state: AppStateModel): IOwner {
    return state.currentOwner;
  }

  @Selector([AppState])
  static currentPatient(state: AppStateModel): IPatient {
    return state.currentPatient;
  }
}
