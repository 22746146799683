import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay, take } from 'rxjs/operators';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Users } from '@app/store/app/app.actions';
import { Router } from '@angular/router';
import { AppConfigService } from '@app/modules/core/config/config.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('drawer') public drawer: ElementRef;
  public currentYear = new Date().getFullYear();

  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private renderer: Renderer2,
    private actions: Actions,
    private router: Router,
    private appConfig: AppConfigService
  ) {}

  public get aboutLink(): string {
    return this.appConfig.baseUrl + '/about';
  }

  public get helpLink(): string {
    return this.appConfig.baseUrl + '/help';
  }

  public get contactLink(): string {
    return this.appConfig.baseUrl + '/contact';
  }

  public ngOnInit(): void {
    this.actions
      .pipe(ofActionSuccessful(Users.Logout))
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigate(['login']);
      });
  }

  public ngAfterViewInit(): void {
    const drawer = this.drawer.nativeElement;
    const narrowMenu = localStorage.getItem('narrow-menu');

    if (narrowMenu === '1') {
      this.renderer.addClass(drawer, 'narrow-menu');
    } else {
      this.renderer.removeClass(drawer, 'narrow-menu');
    }
  }

  public toggleNavigation(): void {
    const drawer = this.drawer.nativeElement;

    if (drawer.classList.contains('narrow-menu')) {
      this.renderer.removeClass(drawer, 'narrow-menu');
      localStorage.setItem('narrow-menu', '0');
    } else {
      this.renderer.addClass(drawer, 'narrow-menu');
      localStorage.setItem('narrow-menu', '1');
    }
  }
}
