import Rollbar from 'rollbar';
import version from '../assets/version.json';
import { Injectable, Inject, InjectionToken, ErrorHandler, Injector } from '@angular/core';
import { AppConfigService } from './modules/core/config/config.service';

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory(injector: Injector) {
  const config = injector.get(AppConfigService);
  const rollbarConfig = {
    enabled: config.rollbar.environment === 'production',
    accessToken: config.rollbar.post_client_tem_token,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: config.rollbar.environment,
      code_version: version.version,
      branch: version.branch,
    },
  };

  return new Rollbar(rollbarConfig);
}
