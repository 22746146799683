export const BIRTHDAYS_PER_PAGE = 5;

export function formattedToday(): string {
  let day = new Date().getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  let month = (new Date().getMonth() + 1).toString();
  month = month.length > 1 ? month : '0' + month;
  const dateToday = month + '-' + day;

  return dateToday;
}
