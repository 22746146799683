import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './modules/core/components/not-found/not-found.component';
import { PatientComponent } from './modules/owner/components/patients/patient/patient.component';
import { LayoutComponent } from './modules/shared/components/layouts/layout/layout.component';
import { OwnerFromPatientResolverService } from './modules/shared/services/owner-from-patient-resolver.service';
import { authGuard } from './modules/core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/calendar',
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./modules/authentication/authentication.module').then((m) => m.AuthenticationModule),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [authGuard],
    canLoad: [authGuard],
    children: [
      // {
      //   path: 'communication',
      //   loadChildren: () => import('./modules/communication/communication.module').then((m) => m.CommunicationModule),
      // },
      {
        path: 'owners',
        loadChildren: () => import('./modules/owner/owner.module').then((m) => m.OwnerModule),
      },
      {
        path: 'patients',
        children: [
          {
            path: '',
            redirectTo: '/owners',
            pathMatch: 'full',
          },
          // {
          //   path: ':patientId',
          //   component: PatientComponent,
          //   resolve: { patient: OwnerFromPatientResolverService },
          // },
          // {
          //   path: ':patientId',
          //   loadComponent: () =>
          //     import('./modules/owner/components/patients/patient/patient.component').then((m) => m.PatientComponent),
          //   resolve: { patient: OwnerFromPatientResolverService },
          // },
          // {
          //   path: ':patientId',
          //   // do this redirect in resolver? guard? to grab ownerId first?
          //   redirectTo: '/owners/:ownerId/patients/:patientId',
          //   resolve: { patient: OwnerFromPatientResolverService },
          // },
        ],
      },
      // {
      //   path: 'visits/last',
      //   loadChildren: () => import('./modules/owner/owner.module').then((m) => m.OwnerModule),
      // },
      {
        path: 'calendar',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'documentation',
        loadChildren: () => import('./modules/documentation/documentation.module').then((m) => m.DocumentationModule),
      },
      {
        path: 'administration',
        redirectTo: 'settings',
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'pricetables',
        redirectTo: 'settings/pricetables',
      },
      {
        path: 'events',
        redirectTo: 'settings/events',
      },
      // {
      //   path: 'statistics',
      //   loadChildren: () => import('./modules/statistics/statistics.module').then((m) => m.StatisticsModule),
      // },
      // {
      //   path: 'storage',
      //   loadChildren: () => import('./modules/storage/storage.module').then((m) => m.StorageModule),
      // },
    ],
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // scrollPositionRestoration: 'disabled',
      // onSameUrlNavigation: 'reload'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
