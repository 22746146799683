<div class="container-fluid px-0">
  <div class="d-flex">
    <nav class="navigation" #drawer [ngClass]="(isHandset$ | async) ? 'mobile' : ''">
      <app-navigation></app-navigation>
    </nav>
    <div class="wrapper d-flex flex-fill flex-column justify-content-between">
      <div>
        <app-topbar (emitToggleNavigation)="toggleNavigation()"></app-topbar>
        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>

      <footer class="mt-4 py-3">
        <div class="container-fluid">
          <div class="row justify-content-between">
            <div class="col-12 col-sm"><strong>Copyright</strong> Vetoteka &copy; {{ currentYear }}</div>
            <div class="col-auto">
              <ul class="unordered d-flex mb-0">
                <li class="me-2">
                  <a [href]="aboutLink">{{ 'SHARED.NAVIGATION.ABOUT' | translate }}</a>
                </li>
                <li class="me-2">
                  <a [href]="helpLink">{{ 'SHARED.NAVIGATION.HELP' | translate }}</a>
                </li>
                <li>
                  <a [href]="contactLink">{{ 'SHARED.NAVIGATION.CONTACT' | translate }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>
