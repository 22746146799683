import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Injector, NgModule } from '@angular/core';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { AppConfigService } from './modules/core/config/config.service';

export function createApollo(httpLink: HttpLink, injector: Injector): ApolloClientOptions<any> {
  const appConfig = injector.get(AppConfigService);
  const uri = `${appConfig.backendUrl}/graphql`;

  return {
    link: httpLink.create({ uri }),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    AppConfigService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Injector],
    },
  ],
})
export class GraphQLModule {}
