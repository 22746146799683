import { Injectable } from '@angular/core';
import { InitialDataResponse } from '@app/modules/shared/models';
import { Query, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class initialDataGQL extends Query<InitialDataResponse> {
  document = gql`
    query (
      $activeClinicStatus: [ClinicStatus!]
      $allClinicsStatuses: [ClinicStatus!]
      $activeUserStatus: [DoctorStatus!]
    ) {
      me {
        doctor {
          display_name: displayName
          id
          color
        }
        clinic {
          id
          name
          color
        }
        company {
          id
        }
      }
      activeUsers: doctorsList(statusIn: $activeUserStatus) {
        collection {
          display_name: displayName
          id
          color
        }
        metadata {
          totalCount
        }
      }
      activeClinics: clinicsList(statusIn: $activeClinicStatus) {
        collection {
          id
          name
          color
        }
        metadata {
          totalCount
        }
      }
      allClinics: clinicsList(statusIn: $allClinicsStatuses) {
        collection {
          id
          name
        }
        metadata {
          totalCount
        }
      }
    }
  `;
}
