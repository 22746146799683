export enum EventType {
  Appointments = 'appointments',
  Events = 'events',
}

export enum EventChangeType {
  Add = 'add',
  Edit = 'edit',
  Delete = 'delete',
}
