import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    LoadingScreenComponent,
    ValidationMessageComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    TranslateModule,
    FontAwesomeModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoadingScreenComponent,
    FontAwesomeModule,
    ValidationMessageComponent,
    LoaderComponent
  ],
})
export class CoreModule { }
