import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

export class LanguageCustomLoader implements TranslateLoader {
  // todo type
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public getTranslation(lang: string): Observable<any> {
    // Don't use paths array and foreach for file loading automatization.
    // There is probably some kind of racing and require() doesnt work properly inside loops.
    const authenticationModuleJson = require(`./app/modules/authentication/i18n/${lang}.json`);
    const coreModuleJson = require(`./app/modules/core/i18n/${lang}.json`);
    const sharedModuleJson = require(`./app/modules/shared/i18n/${lang}.json`);
    const homeModuleJson = require(`./app/modules/home/i18n/${lang}.json`);
    const settingsModuleJson = require(`./app/modules/settings/i18n/${lang}.json`);
    const communicationModuleJson = require(`./app/modules/communication/i18n/${lang}.json`);
    const ownerModuleJson = require(`./app/modules/owner/i18n/${lang}.json`);
    const documentationModuleJson = require(`./app/modules/documentation/i18n/${lang}.json`);
    const statisticsModuleJson = require(`./app/modules/statistics/i18n/${lang}.json`);
    const storageModuleJson = require(`./app/modules/storage/i18n/${lang}.json`);

    const jsonArray = [
      authenticationModuleJson,
      coreModuleJson,
      sharedModuleJson,
      homeModuleJson,
      settingsModuleJson,
      communicationModuleJson,
      ownerModuleJson,
      documentationModuleJson,
      statisticsModuleJson,
      storageModuleJson,
    ];

    let completeI18n = {};
    jsonArray.forEach((jsonFile) => {
      completeI18n = { ...jsonFile, ...completeI18n };
    });

    return of(completeI18n);
  }
}
