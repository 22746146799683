import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IClinic, IDoctor } from '../models';
import { AppConfigService } from '@app/modules/core/config/config.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private apiUrl: string;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  public getCurrentUser(): Observable<IDoctor> {
    return this.http.get<IDoctor>(`${this.apiUrl}/me`);
  }

  public getCurrentClinic(): Observable<IClinic> {
    return this.http.get<IClinic>(`${this.apiUrl}/me/clinic`);
  }

  public logout(): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/auth`);
  }
}
