import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { EMPTY, catchError, map, of } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { Store } from '@ngxs/store';
import { App, Users } from '@app/store/app/app.actions';
import { AppStateSelectors } from '@app/store/app/app.selectors';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const loading = inject(LoadingService);
  const store = inject(Store);
  const toastr = inject(ToastrService);
  const translate = inject(TranslateService);

  return store.dispatch(new App.LoadCurrentUserInitialData()).pipe(
    map((a) => true),
    catchError((error) => {
      const currentUserSnapshot = store.selectSnapshot(AppStateSelectors.currentUser);

      if (currentUserSnapshot) {
        store.dispatch(new Users.Logout());
      }

      const title = translate.instant('CORE.401.MESSAGE.TITLE');
      const message = translate.instant('CORE.401.MESSAGE.CONTENT');
      toastr.error(message, title);

      router.navigate(['login']);
      loading.isLoading.next(false);

      return of(error);
    })
  );
};

export const authGuardChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  authGuard(route, state);
