import { Inject, Injectable } from '@angular/core';
import { IAppConfig, IAppSettings, IRollbarSettings, ISyncfusionSettings } from '../models';
import { APP_CONFIG } from '@app/app.module';

@Injectable()
export class AppConfigService {
  private config: IAppConfig = {} as IAppConfig;

  constructor(@Inject(APP_CONFIG) private readonly appConfig: IAppConfig) {
    this.config = this.appConfig;
  }

  public get production() {
    return this.config.production;
  }

  public get baseUrl() {
    return this.config.baseUrl;
  }

  public get apiUrl() {
    return this.config.apiUrl;
  }

  public get backendUrl() {
    return this.config.backendUrl;
  }

  public get rollbar(): IRollbarSettings {
    return this.config.rollbar;
  }

  public get syncfusion(): ISyncfusionSettings {
    return this.config.syncfusion;
  }

  public get settings(): IAppSettings {
    return this.config.settings;
  }
}
