import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { APP_CONFIG, AppModule } from './app/app.module';
import { IAppConfig } from '@app/modules/core/models';

const jsonFile = 'assets/config.json';

fetch(jsonFile)
  .then((response) => response.json())
  .then((config: IAppConfig) => {
    if (config.production) {
      enableProdMode();
    }

    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  })
  .catch((error) => {
    throw Error(`Could not load file '${jsonFile}': ${JSON.stringify(error)}`);
  });
