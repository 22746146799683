import { HttpHeaders } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { IOwner, IPatient } from '@app/modules/shared/models';

export namespace App {
  export class LoadCurrentUserInitialData {
    static readonly type = '[App] Load Current User Initial Data';
  }
}

export namespace Users {
  export class LoadActive {
    static readonly type = '[Users] Load Active';
  }

  export class Logout {
    static readonly type = '[Users] Logout';
  }
}

export namespace Clinics {
  export class LoadActive {
    static readonly type = '[Clinics] Load Active';
  }
}

export namespace Owners {
  export class Add {
    static readonly type = '[Owners] Add';
    constructor(public ownerForm: UntypedFormGroup) {}
  }

  export class Edit {
    static readonly type = '[Owners] Edit';
    constructor(public ownerForm: UntypedFormGroup) {}
  }

  export class Load {
    static readonly type = '[Owners] Load';
    constructor(public id: number) {}
  }

  export class SetCurrent {
    static readonly type = '[Owners] Set Current';
    constructor(public owner: IOwner) {}
  }

  export class LoadCurrent {
    static readonly type = '[Owners] Load Current';
    constructor(public id: number) {}
  }

  export class AddPatient {
    static readonly type = '[Owners] Add Patient';
    constructor(public patient: IPatient) {}
  }
}

export namespace Patients {
  export class LoadBirthdays {
    static readonly type = '[Patients] Load Birthdays';
    constructor(public page: number = 1, public headers: HttpHeaders = null) {}
  }

  export class Add {
    static readonly type = '[Patients] Add';
    constructor(public patientForm: UntypedFormGroup) {}
  }

  export class Edit {
    static readonly type = '[Patients] Edit';
    constructor(public patientForm: UntypedFormGroup) {}
  }

  export class Remove {
    static readonly type = '[Patients] Remove';
    constructor(public id: number) {}
  }

  export class Load {
    static readonly type = '[Patients] Load';
    constructor(public id: number) {}
  }

  export class SetCurrent {
    static readonly type = '[Patients] Set Current';
    constructor(public patient: IPatient) {}
  }

  export class LoadCurrent {
    static readonly type = '[Patients] Load Current';
    constructor(public id: number) {}
  }

  export class LoadWeight {
    static readonly type = '[Patients] Load Weight';
    constructor(public id: number) {}
  }

  export class LoadExaminations {
    static readonly type = '[Patients] Load Examinations';
    constructor(public id: number) {}
  }
}
