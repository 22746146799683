import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { LoadingService } from '../services/loading.service';
import { Observable, finalize } from 'rxjs';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private serviceCount = 0;

  constructor(private loadingService: LoadingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.get('skip')) {
      return next.handle(request);
    }

    this.serviceCount++;
    this.loadingService.isLoading.next(true);

    return next.handle(request).pipe(
      finalize(() => {
        this.serviceCount--;

        if (this.serviceCount === 0) {
          this.loadingService.isLoading.next(false);
        }
      })
    );
  }
}
