import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private titleService: Title,
    private translate: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.titleService.setTitle(this.translate.instant('CORE.404.TITLE'));
  }
}
