import { FormGroup } from '@angular/forms';
import { IOwner, IOwnerContactForm, IOwnerForm } from '@app/modules/shared/models';

export const OWNERS_PER_PAGE_IN_MODAL = 10;
export const OWNER_APPOINTMENTS_PER_PAGE = 10;
export const OWNER_APPOINTMENTS_PER_PAGE_IN_MODAL = 5;

export const mapOwnerFormValuesForApi = (ownerForm: FormGroup<IOwnerForm>): Partial<IOwner> => {
  const contact = ownerForm.get('contact') as FormGroup<IOwnerContactForm>;

  const ownerData = {
    ...ownerForm.value,
    ...contact.value,
  };

  return ownerData;
};
